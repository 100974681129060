import React from "react"
import styled from "styled-components"

const StyledMap = styled.iframe`
    width: 100%;
    height: 500px;
    border: none;
`

const Map = () => (
  <StyledMap
    title="map"
    frameborder="0"
    allowfullscreen="false"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2394.167133155494!2d23.262976977707307!3d53.12514529125013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471fff095524797f%3A0xb8c8033adecea342!2sSklep%20Mywash!5e0!3m2!1spl!2spl!4v1728646574971!5m2!1spl!2spl"
  ></StyledMap>
)

export default Map